<template>
  <Button
    class="p-button-text p-button-secondary"
    @click="toggle"
    icon="pi pi-ellipsis-h"
  />
  <Menu :model="items" ref="menu" :popup="true" />
</template>

<script>
import { ref, defineComponent } from "vue";
import { projectFunctions } from "../../firebase/config";
import { useToast } from "primevue/usetoast";

export default defineComponent({
  emits: ["update"],
  props: ["data"],
  setup(props, { emit }) {
    const toast = useToast();

    const menu = ref();

    const items = ref([
      {
        visible: !props.data.disabled,
        label: "Désactiver",
        command: async () => {
          await projectFunctions.httpsCallable("updateUserDisabled")({
            uid: props.data.uid,
            disabled: true,
          });
          emit("update");
          toast.add({
            severity: "info",
            detail: "Utilisateur désactivé",
            life: 1500,
          });
        },
      },
      {
        visible: props.data.disabled,
        label: "Activer",
        command: async () => {
          await projectFunctions.httpsCallable("updateUserDisabled")({
            uid: props.data.uid,
            disabled: false,
          });
          emit("update");
          toast.add({
            severity: "success",
            detail: "Utilisateur réactivé",
            life: 1500,
          });
        },
      },
      { label: "Réinitialiser mot de passe" },
    ]);

    const toggle = (event) => {
      menu.value.toggle(event);
    };

    return { menu, items, toggle };
  },
});
</script>

<style lang="scss" scoped></style>
