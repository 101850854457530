<template>
  <form
    @submit.prevent="onSubmit"
    id="form-create"
    class="p-field p-4"
    autocomplete="off"
  >
    <h4>Informations</h4>
    <div class="flex">
      <div class="p-inputgroup col-6">
        <span class="p-inputgroup-addon" style="width: 80px">Prénom</span>
        <InputText id="firstName" v-model="firstName" tabindex="1"></InputText>
      </div>
      <div class="v-spacer" />
      <div class="p-inputgroup col-6">
        <span class="p-inputgroup-addon">
          <i class="pi pi-envelope"></i>
        </span>
        <InputText
          id="email"
          v-model="email"
          placeholder="Adresse email"
          tabindex="3"
        ></InputText>
      </div>
    </div>
    <div class="flex">
      <div class="p-inputgroup col-6">
        <span class="p-inputgroup-addon" style="width: 80px">Nom</span>
        <InputText id="lastName" v-model="lastName" tabindex="2"></InputText>
      </div>
      <div class="v-spacer" />
      <div class="p-inputgroup col-6">
        <span class="p-inputgroup-addon"><i class="pi pi-shield"></i></span>
        <Dropdown
          id="role"
          :options="roles"
          optionValue="id"
          optionLabel="label"
          v-model="role"
          placeholder="Rôle"
          tabindex="4"
        />
      </div>
    </div>
    <h4 class="mt-3">Accès</h4>
    <div class="flex">
      <div class="p-field col-8">
        <label for="centers">Etablissements</label>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"
            ><i class="pi pi-briefcase"></i
          ></span>
          <MultiSelect
            id="centers"
            v-model="centers"
            :options="docs"
            optionLabel="name"
            optionValue="id"
            placeholder="Selectionnez"
            display="chip"
            :loading="pending"
            tabindex="5"
          />
        </div>
      </div>
      <div class="p-field col-4">
        <label for="password">Mot de passe</label>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-lock"></i></span>
          <Password
            v-model="password"
            toggleMask
            tabindex="6"
            placeholder="8 caratères min."
          ></Password>
          <Button
            icon="pi pi-refresh"
            class="p-button-secondary"
            @click="onGeneratePass"
            tabindex="7"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-content-end mt-3">
      <Button
        label="Effacer"
        class="p-button-text p-button-secondary ml-auto mr-2"
        icon="pi pi-times"
        @click="onClear"
      />
      <Button
        label="Ajouter"
        class="p-button-success mr-2"
        type="submit"
        icon="pi pi-plus"
        :loading="registerPending"
      />
    </div>
  </form>
</template>

<script>
import { reactive, toRefs, defineComponent } from "vue";
import useDatabase from "../../composables/useDatabase";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import useAuth from "../../composables/useAuth";

export default defineComponent({
  emits: ["update"],
  setup(props, { emit }) {
    const confirm = useConfirm();
    const toast = useToast();

    const newUser = reactive({
      firstName: "",
      lastName: "",
      email: "",
      centers: [],
      role: undefined,
      password: "",
      passwordConfirm: "",
    });

    const { docs, pending } = useDatabase("centers").getAll();

    const { docs: roles } = useDatabase("users/roles").getAll();

    function randPassword(letters, numbers, either) {
      let chars = [
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", // letters
        "0123456789", // numbers
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789", // either
      ];

      return [letters, numbers, either]
        .map(function (len, i) {
          return Array(len)
            .fill(chars[i])
            .map(function (x) {
              return x[Math.floor(Math.random() * x.length)];
            })
            .join("");
        })
        .concat()
        .join("")
        .split("")
        .sort(function () {
          return 0.5 - Math.random();
        })
        .join("");
    }

    const onGeneratePass = (event) => {
      const randomPass = randPassword(3, 3, 2);
      confirm.require({
        target: event.currentTarget,
        icon: "pi pi-lock",
        message: `Mot de passe généré: ${randomPass}`,
        rejectClass: "hidden",
        acceptLabel: "Copier",
        accept: async () => {
          newUser.password = randomPass;
          newUser.passwordConfirm = randomPass;
          await navigator.clipboard.writeText(randomPass);
          toast.add({
            severity: "info",
            detail: "Mot de passe copié !",
            life: 1500,
          });
        },
      });
    };

    const onClear = () => {
      newUser.firstName = "";
      newUser.lastName = "";
      newUser.email = "";
      newUser.centers = [];
      newUser.role = undefined;
      newUser.password = "";
      newUser.passwordConfirm = "";
    };

    const { register, pending: registerPending } = useAuth();
    const onSubmit = async () => {
      const data = {
        email: newUser.email,
        password: newUser.password,
        profile: {
          firstName: newUser.firstName,
          lastName: newUser.lastName,
        },
        centers: newUser.centers,
        role: newUser.role,
      };
      await register(
        data.email,
        data.password,
        data.profile,
        data.centers,
        data.role
      );
      onClear();
      toast.add({
        severity: "success",
        detail: "Nouvel utilisateur ajouté !",
      });
      emit("update");
    };

    return {
      onSubmit,
      registerPending,
      onGeneratePass,
      ...toRefs(newUser),
      roles,
      docs,
      pending,
      onClear,
    };
  },
});
</script>

<style lang="scss" scoped>
h4 {
  padding: 0.75rem 0 !important;
}
</style>
