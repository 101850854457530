<template>
  <Button
    icon="pi pi-pencil"
    class="p-button-text p-button-secondary"
    @click="visible = true"
  />
  <Dialog
    modal
    :draggable="false"
    v-model:visible="visible"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '50vw' }"
  >
    <template #header>
      <h2 class="display-4">
        Modifier <strong>{{ email }}</strong>
      </h2>
    </template>
    <template #default>
      <form id="form" @submit.prevent="onSubmit" class="p-field">
        <div class="flex">
          <div class="p-fluid col-6">
            <label for="firstName">Prénom</label>
            <InputText id="firstName" v-model="firstName" />
          </div>
          <div class="v-spacer" />
          <div class="col-6">
            <label for="role">Rôle</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"
                ><i class="pi pi-shield"></i
              ></span>
              <Dropdown
                id="role"
                :options="rolesList"
                optionValue="id"
                optionLabel="label"
                v-model="role"
                placeholder="Selectionnez"
                tabindex="4"
                :loading="rolesPending"
              />
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="p-fluid col-6">
            <label for="lastName">Nom</label>
            <InputText id="lastName" v-model="lastName" />
          </div>
          <div class="v-spacer" />
          <div class="p-fluid col-6">
            <label for="passwordReset">Mot de passe</label>
            <Button label="Réinitialiser le mot de passe"></Button>
          </div>
        </div>
        <div class="flex">
          <div class="col">
            <label for="centers">Etablissements</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"
                ><i class="pi pi-briefcase"
              /></span>
              <MultiSelect
                id="centers"
                v-model="centers"
                :options="centersList"
                optionLabel="name"
                optionValue="id"
                placeholder="Selectionnez"
                display="chip"
                :loading="centersPending"
                tabindex="3"
                :filter="true"
              />
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <div class="flex justify-content-end">
        <Button
          label="Annuler"
          @click="visible = false"
          class="p-button-text p-button-secondary"
        />
        <Button
          label="Enregistrer"
          type="submit"
          form="form"
          icon="pi pi-save"
          :disabled="pending"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { reactive, ref, toRefs } from "vue";
import useDatabase from "../../composables/useDatabase";
import { projectFunctions } from "../../firebase/config";
import { useToast } from "primevue/usetoast";

export default {
  emits: ["update"],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const toast = useToast();
    const visible = ref(false);

    const user = reactive({
      firstName: props.data.displayName?.split(" ")[0] || "",
      lastName: props.data.displayName?.split(" ")[1] || "",
      email: props.data.email,
      role: props.data.customClaims.role,
      centers: props.data.customClaims.centers
        ? Object.keys(props.data.customClaims.centers)
        : [],
    });

    const { docs: rolesList, pending: rolesPending } =
      useDatabase("users/roles").getAll();

    const { docs: centersList, pending: centersPending } =
      useDatabase("centers").getAll();

    const pending = ref(false);
    const error = ref(false);

    const onSubmit = async () => {
      const updatedUser = {
        uid: props.data.uid,
        displayName: `${user.firstName || ""} ${user.lastName || ""}`,
        role: user.role,
        centers: user.centers,
      };
      pending.value = true;
      try {
        await projectFunctions.httpsCallable("updateUser")(updatedUser);
        visible.value = false;
        emit("update");
        toast.add({
          severity: "success",
          detail: `L'utilisateur ${props.data.email} à été modifié !`,
          life: 2500,
        });
      } catch (e) {
        error.value = e.message;
      } finally {
        pending.value = false;
      }
    };

    return {
      visible,
      onSubmit,
      pending,
      ...toRefs(user),
      rolesList,
      rolesPending,
      centersList,
      centersPending,
    };
  },
};
</script>

<style lang="scss" scoped></style>
