<template>
  <Accordion :activeIndex="[1]" :multiple="true">
    <AccordionTab header="Ajouter un utilisateur">
      <CreateUser @update="listUsers"></CreateUser>
    </AccordionTab>
    <AccordionTab header="Utilisateurs">
      <DataTable
        :value="users"
        stripedRows
        rowHover
        :rows="rows"
        :paginator="true"
        class="p-datatable-sm"
        v-model:filters="filters"
        filterDisplay="row"
        v-model:selection="selectedUsers"
        :loading="pending"
      >
        <template #empty> Aucun article trouvé. </template>
        <template #loading> Chargement des données. </template>
        <template #footer>
          <div class="flex align-items-center justify-content-end">
            <div>
              <label class="mr-2">Afficher:</label>
              <Dropdown :options="[10, 25, 50, 100]" v-model="rows" />
            </div>
          </div>
        </template>
        <Column
          selectionMode="multiple"
          style="text-align: center; width: 48px"
        />
        <Column
          filterField="displayName"
          header="Nom"
          class="col-2"
          :showFilterMenu="false"
        >
          <template #filter="{ filterModel, filterCallback }">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filterModel.value"
                @input="filterCallback()"
                class="p-inputtext-sm"
                placeholder="Rechercher par nom"
              />
            </span>
          </template>
          <template #body="{ data }">
            <span :class="data.disabled ? 'disabled' : ''">{{
              data.displayName
            }}</span>
          </template>
        </Column>
        <Column
          filterField="email"
          header="Adresse email"
          class="col"
          :showFilterMenu="false"
        >
          <template #filter="{ filterModel, filterCallback }">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filterModel.value"
                @input="filterCallback()"
                class="p-inputtext-sm"
                placeholder="Rechercher par email"
              />
            </span>
          </template>
          <template #body="{ data }">
            <span :class="data.disabled ? 'disabled' : ''">{{
              data.email
            }}</span>
          </template>
        </Column>
        <Column header="Role" class="col-1">
          <template #body="{ data }">
            <Badge
              v-if="!rolesPending"
              :value="roles[data.customClaims.role]?.label"
              :class="data.disabled ? 'opacity-50' : ''"
            />
          </template>
        </Column>
        <Column header="Etablissements" class="col-2">
          <template #body="{ data }">
            <!-- TODO: implement rure based verification -->
            <Badge
              v-if="data.customClaims.role === 'admin'"
              :value="'Tous'"
              class="custom-badge bg-info"
            ></Badge>
            <span
              v-else
              v-for="(i, center) in data.customClaims.centers"
              :key="center"
            >
              <Badge :value="centers[center]?.name" class="custom-badge" />
            </span>
          </template>
        </Column>
        <Column
          :showFilterMenu="false"
          class="ml-auto col-2 text-right justify-content-end"
        >
          <template #filter>
            <Button
              icon="pi pi-refresh"
              class="p-button-text p-button-sm"
              @click="listUsers"
            />
          </template>
          <template #body="{ data }">
            <keep-alive>
              <component
                :is="'EditUser'"
                :key="data.uid"
                :data="data"
                @update="listUsers"
              />
            </keep-alive>
            <ContextActions
              :data="{ uid: data.uid, disabled: data.disabled }"
              @update="listUsers"
            />
            <!--            </template>-->
          </template>
        </Column>
      </DataTable>
    </AccordionTab>
  </Accordion>
</template>

<script>
import { onMounted, ref } from "vue";
import {
  projectFunctions,
  projectDatabase,
  projectAuth,
} from "@/firebase/config";
import useDatabase from "../../composables/useDatabase";

import CreateUser from "./_Create";
import ContextActions from "./_ContextActions";
import EditUser from "./_Edit";

import { FilterMatchMode } from "primevue/api";

export default {
  components: { CreateUser, ContextActions, EditUser },
  setup() {
    const users = ref([]);
    const currentUser = ref(projectAuth.currentUser);

    const centers = ref([]);
    const pending = ref(false);

    const listUsers = async () => {
      pending.value = true;
      const result = await projectFunctions
        .httpsCallable("listUsers")
        .call(null);
      users.value = result.data;
      const snapshot = await projectDatabase.ref("centers").get();
      centers.value = snapshot.val();
      pending.value = false;
    };

    onMounted(async () => {
      await listUsers();
    });

    const { docs: roles, pending: rolesPending } =
      useDatabase("users/roles").mapAll();

    const selectedUsers = ref([]);

    const rows = ref(10);

    const filters = ref({
      displayName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    return {
      users,
      pending,
      centers,
      roles,
      rolesPending,
      listUsers,
      selectedUsers,
      filters,
      rows,
      currentUser,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-accordion-tab) {
  .p-accordion-content {
    padding: 0;
  }
}

.custom-badge {
  background-color: $secondary-light;
  margin-right: 0.3rem !important;
  &:last-child {
    margin-right: 0;
  }
}
</style>
